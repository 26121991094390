// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { useRef, useEffect } from 'react';

const useEventListener = (event, handler, element, addFn = 'addEventListener', removeFn = 'removeEventListener') => {
    const handlerRef = useRef();

    useEffect(() => {
        handlerRef.current = handler;
    }, [handler]);

    useEffect(
        () => {
            if (!element || !element[addFn]) {
                return;
            }

            const listener = event => handlerRef.current(event);

            element[addFn](event, listener);

            return () => {
                element[removeFn](event, listener);
            };
        },
        [event, element, addFn, removeFn],
    );
};

export default useEventListener;
