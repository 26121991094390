// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { readLocalStorageValue } from 'common/helpers/readLocalStorageValue';

const DEFAULT_INPUT = {
    pagination: { page: 1, itemsPerPage: 25 },
};

// Bump version after GraphQL API changes to prevent reading of invalid input from storage
const VERSION = 2;

export const getGraphQlListInputStorageArgs = ({
    key,
    withPagination = true,
    defaultInput,
}) => [
    `${key}ListInput.v${VERSION}`,
    defaultInput || (withPagination ? DEFAULT_INPUT : {}),
];

export const readGraphQlListInputFromStorage = options => readLocalStorageValue(
    ...getGraphQlListInputStorageArgs(options),
);
