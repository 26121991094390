// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate as BaseTranslate } from '@plesk/ui-library';

const Translate = ({ component, ...props }) => (
    <BaseTranslate component={component} {...props} />
);

Translate.propTypes = {
    component: PropTypes.elementType,
};

Translate.defaultProps = {
    component: Fragment,
};

export default Translate;
