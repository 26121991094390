// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    Action,
    Button,
    Column,
    Columns,
    StatusMessage,
    ExtendedStatusMessage,
    ExtendedStatusMessageDescription,
    ExtendedStatusMessageTitle, Link, LocaleProvider, Spot,
    Text, Translate, Icon,
} from '@plesk/ui-library';
import { api, redirect, UAT } from 'jsw';
import './DynamicListBanner.less';

export const dynamicListBannerFeedbackHandler = () => {
    UAT.dispatchAction('DYNAMICLIST_BANNER_STARTED_FEEDBACK');
    return true;
};

export const DynamicListBannerHint = ({ selector }) => {
    const [closed, setClosed] = useState(false);

    const handleClose = () => {
        setClosed(true);
    };

    return !closed &&
        <Spot
            selector={selector}
            popup={{
                size: 'md',
                placement: 'bottom',
                onClose: handleClose,
                buttons: [(
                    <Button
                        key="finish"
                        onClick={handleClose}
                        intent="primary"
                        data-type="close-banner-hint"
                    >
                        <Translate content="closedBannerButton" />
                    </Button>
                )],
                children: <Translate content="closedBannerHint" />,
            }}
        />;
};

DynamicListBannerHint.propTypes = {
    selector: PropTypes.string.isRequired,
};

const DynamicListBanner = ({ bannerInfo, onTourStart, listMode, dropdownSelector, canStartTour }) => {
    const [showHint, setShowHint] = useState(false);
    const [discarded, setDiscarded] = useState(bannerInfo.discarded);

    const { enabled, collapsed, feedbackUrl, locale, initialType } = bannerInfo;
    const [isCollapsed, setIsCollapsed] = useState(collapsed);

    const handleCloseBanner = () => {
        setDiscarded(true);
        setShowHint(true);
        api.post('/smb/web/dynamic-list-banner-hide');
        UAT.dispatchAction('DYNAMICLIST_BANNER_CLOSED');
    };

    const handleSwitchView = () => {
        UAT.dispatchAction('DYNAMICLIST_BANNER_SWITCHED_VIEW', {
            listType: 'dynamic',
        });
        redirect('/smb/web/view?list-type=dynamic');
    };

    const handleToggleBanner = () => {
        api.post('/smb/web/active-list-banner-toggle-collapsed', { collapsed: !isCollapsed });
        setIsCollapsed(!isCollapsed);
    };

    const handleSwitchBackView = async () => {
        await api.post('/smb/web/dynamic-list-banner-switch');
        UAT.dispatchAction('DYNAMICLIST_BANNER_SWITCHED_VIEW', { listType: initialType });
        redirect(`/smb/web/view?list-type=${initialType}`);
    };

    const switchBackText = () => bannerInfo.initialType === 'active' ? 'switchBackToActive' : 'switchBackToClassic';

    const isDynamic = listMode === 'dynamic';
    const intent = isDynamic ? 'success' : 'warning';
    const contentText = canStartTour ? 'switched.Text' : 'switched.TextWithoutTour';

    const contentTextParams = {
        link: (
            <Link onClick={onTourStart}>
                <Translate component={Text} bold content="switched.LinkText" />
            </Link>
        ),
        listType: <Translate content={listMode === 'active' ? 'activeList' : 'classicList'} />,
    };

    if (!enabled) {
        return null;
    }

    if (!isDynamic) {
        return (
            <LocaleProvider messages={locale}>
                <StatusMessage intent="warning" className="dynamic-list-banner">
                    <div className="dynamic-list-banner__heading">
                        <Translate content="initial.Header" />
                        <Button
                            onClick={handleToggleBanner}
                            className="dynamic-list-banner__heading-button"
                            icon={isCollapsed ? 'chevron-down' : 'chevron-up'}
                            ghost
                        />
                    </div>
                    <div
                        className={classNames({
                            'dynamic-list-banner__body': true,
                            'dynamic-list-banner__body--expanded': !isCollapsed,
                        })}
                    >
                        <Translate
                            content="initial.Text"
                            params={{
                                dynamicListLink: (
                                    <a
                                        href="https://www.plesk.com/blog/product-technology/dynamic-list-vs-active-list-a-comprehensive-comparison-unveiling-the-ultimate-winner/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Translate content="dynamicListDescLink" />
                                    </a>
                                ),
                            }}
                        />
                        <div className="dynamic-list-banner__actions">
                            <Action icon="projects" onClick={handleSwitchView}>
                                <Translate content="switchToDynamic" />
                            </Action>
                            {feedbackUrl && (
                                <Action
                                    id="feedback"
                                    icon="feedback"
                                    href={feedbackUrl}
                                    target="_blank"
                                    onClick={dynamicListBannerFeedbackHandler}
                                >
                                    <Translate content="shareFeedback" />
                                </Action>
                            )}
                            <Action
                                id="chat"
                                icon="chat"
                                target="_blank"
                                href="https://talk.plesk.com/threads/active-list-will-be-discontinued-and-replaced-by-dynamic-list-starting-march-2024.370757/"
                            >
                                <Translate content="discussOnForum" />
                            </Action>
                        </div>
                    </div>
                </StatusMessage>
            </LocaleProvider>
        );
    }

    return (
        <LocaleProvider messages={locale}>
            {!discarded && (
                <ExtendedStatusMessage
                    intent={intent}
                    image={<Icon src={require('images/dynamic-list-promotion.png')} size="48" />}
                    className="dynamic-list-banner"
                >
                    <Columns>
                        <Column fill>
                            <Columns>
                                <Column maxWidth="70%">
                                    <ExtendedStatusMessageTitle>
                                        <Translate content="switched.Header" />
                                    </ExtendedStatusMessageTitle>
                                    <ExtendedStatusMessageDescription>
                                        <Translate content={contentText} params={contentTextParams} />
                                    </ExtendedStatusMessageDescription>
                                    <ExtendedStatusMessageDescription>
                                        <Columns gap="md">
                                            {feedbackUrl && (
                                                <Column>
                                                    <Action
                                                        icon="feedback"
                                                        href={feedbackUrl}
                                                        target={'_blank'}
                                                        onClick={dynamicListBannerFeedbackHandler}
                                                    >
                                                        <Translate
                                                            content="shareFeedback"
                                                        />
                                                    </Action>
                                                </Column>
                                            )}
                                            {bannerInfo.initialType !== 'dynamic' && (
                                                <Column>
                                                    <Action
                                                        icon="arrow-back"
                                                        onClick={handleSwitchBackView}
                                                    >
                                                        <Translate
                                                            content={switchBackText()}
                                                        />
                                                    </Action>
                                                </Column>
                                            )}
                                        </Columns>
                                    </ExtendedStatusMessageDescription>
                                </Column>
                            </Columns>
                        </Column>
                        <Column>
                            <Button ghost icon="cross-mark" onToggle={handleCloseBanner} />
                        </Column>
                    </Columns>
                </ExtendedStatusMessage>
            )}
            {showHint && <DynamicListBannerHint selector={dropdownSelector} />}
        </LocaleProvider>
    );
};

DynamicListBanner.propTypes = {
    onTourStart: PropTypes.func,
    bannerInfo: PropTypes.shape({
        locale: PropTypes.object.isRequired,
        enabled: PropTypes.bool,
        discarded: PropTypes.bool,
        collapsed: PropTypes.bool,
        feedbackUrl: PropTypes.string,
        switched: PropTypes.bool.isRequired,
        initialType: PropTypes.string.isRequired,
    }).isRequired,
    listMode: PropTypes.string.isRequired,
    dropdownSelector: PropTypes.string.isRequired,
    canStartTour: PropTypes.bool,
};

DynamicListBanner.defaultProps = {
    onTourStart: () => true,
    canStartTour: false,
};

export default DynamicListBanner;
