// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import apolloClient from '../apolloClient';
import { gql } from '@apollo/client';
import { redirect } from 'react-router-dom';
import { getId } from 'helpers/getId';

export const SummaryReportRoute = {
    path: '/cp/summary-report',
    children: [{
        index: true,
        loader: async () => {
            const { data: { viewer: { summaryReportLayouts } } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            summaryReportLayouts {
                                id
                                isDefault
                            }
                        }
                    }
                `,
            });

            const defaultId = summaryReportLayouts.find(({ isDefault }) => isDefault)?.id ?? summaryReportLayouts[0]?.id;
            if (defaultId) {
                return redirect(getId(defaultId));
            }

            throw new Response('Not Found', { status: 404 });
        },
    }, {
        path: ':layoutId',
        children: [{
            index: true,
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage" */'components/pages/SummaryReportPage'),
            loader: async ({ params: { layoutId } }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
                return data;
            },
        }, {
            path: 'print',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportPage" */'components/pages/SummaryReportPage/SummaryReportPrintPage'),
            loader: async ({ params: { layoutId } }) => {
                await apolloClient.query({
                    query: require('queries/SummaryReportPrintPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
            },
        }, {
            path: 'schedule',
            componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportSchedulesPage" */'components/pages/SummaryReportSchedulesPage'),
            loader: async ({ params: { layoutId } }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportSchedulesPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
                return data;
            },
            children: [{
                path: 'add',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/SummaryReportSchedulesPage" */
                    'components/pages/SummaryReportSchedulesPage/AddPage'
                ),
                loader: async ({ params: { layoutId } }) => {
                    const { data } = await apolloClient.query({
                        query: require('queries/SummaryReportScheduleAddPage.graphql'),
                        variables: {
                            layoutId: btoa(`SummaryReportLayout:${layoutId}`),
                        },
                    });
                    return data;
                },
            }, {
                path: ':scheduleId/edit',
                componentLoader: () => import(
                    /* webpackChunkName: "components/pages/SummaryReportSchedulesPage" */
                    'components/pages/SummaryReportSchedulesPage/EditPage'
                ),
                loader: async ({ params: { scheduleId, layoutId } }) => {
                    const { data } = await apolloClient.query({
                        query: require('queries/SummaryReportScheduleEditPage.graphql'),
                        variables: {
                            scheduleId: btoa(`SummaryReportSchedule:${scheduleId}`),
                            layoutId: btoa(`SummaryReportLayout:${layoutId}`),
                        },
                    });
                    return data;
                },
            }],
        }],
    }, {
        path: 'layouts',
        componentLoader: () => import(/* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */'components/pages/SummaryReportLayoutsPage'),
        loader: async () => {
            const { data } = await apolloClient.query({
                query: require('queries/SummaryReportLayoutsPage.graphql'),
            });
            return data;
        },
        children: [{
            path: 'add',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */
                'components/pages/SummaryReportLayoutsPage/AddPage'
            ),
            loader: async () => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportLayoutAddPage.graphql'),
                });
                return data;
            },
        }, {
            path: ':layoutId/edit',
            componentLoader: () => import(
                /* webpackChunkName: "components/pages/SummaryReportLayoutsPage" */
                'components/pages/SummaryReportLayoutsPage/EditPage'
            ),
            loader: async ({ params: { layoutId } }) => {
                const { data } = await apolloClient.query({
                    query: require('queries/SummaryReportLayoutEditPage.graphql'),
                    variables: { id: btoa(`SummaryReportLayout:${layoutId}`) },
                });
                return data;
            },
        }],
    }, {
        path: 'traffic-history',
        componentLoader: () => import(/* webpackChunkName: "components/pages/TrafficHistoryReportPage" */'components/pages/TrafficHistoryReportPage'),
        loader: async () => {
            const { data } = await apolloClient.query({
                query: require('queries/TrafficHistoryReportPage.graphql'),
            });
            return data;
        },
    }],
};
