// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink, useNavigate } from 'react-router-dom';
import { createPath } from 'react-router';

const urlMapping = {
    '/plesk/admin-panel=server.tools/': '/admin/server/tools',
};

export const useNavigateCallback = () => {
    const navigate = useNavigate();

    return useCallback(e => {
        if (e.metaKey || e.altKey || e.ctrlKey || e.shiftKey) {
            return;
        }

        const url = e.target.closest('a').getAttribute('href');
        if (!require('routes').isClientSideRedirectAllowed(url)) {
            return;
        }

        e.preventDefault();
        navigate(url);
    }, [navigate]);
};

const Link = ({ to, state, disabled, preventScrollReset, ...props }) => {
    if (disabled) {
        to = undefined;
        props.onClick = undefined;
    }

    if (urlMapping[to]) {
        to = urlMapping[to];
    }

    if (to && require('routes').isClientSideRedirectAllowed(to)) {
        return <RouteLink to={to} state={state} preventScrollReset={preventScrollReset} {...props} />;
    }

    if (typeof to === 'object' && to !== null) {
        to = createPath(to);
    }

    return <a href={to} {...props} />;
};

Link.propTypes = {
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    state: PropTypes.any,
    disabled: PropTypes.bool,
    preventScrollReset: PropTypes.bool,
};

Link.defaultProps = {
    to: undefined,
    state: undefined,
    disabled: undefined,
    preventScrollReset: undefined,
};

export default Link;
