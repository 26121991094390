// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import IntlMessageFormat from 'intl-messageformat';
import api from './api';
import Observer from './Observer';
import BaseTranslate from 'components/common/Translate';

/**
 * @class Locale
 */
class Locale {
    static sections = {};

    /**
     * Register new locale section
     * @param {String} name
     * @param {Object} [messages]
     * @returns {Locale}
     */
    static addSection(name, messages) {
        this.sections[name] = new Locale(messages);
        Observer.notify({ name, messages }, 'plesk:addedLocaleSection');

        return this.sections[name];
    }

    /**
     * Returns registered locale section or created empty section
     * @param {String} name
     * @returns {Locale}
     */
    static getSection(name) {
        if (!this.sections[name]) {
            return new Locale();
        }
        return this.sections[name];
    }

    static getTranslate(name) {
        const Translate = props => <BaseTranslate namespace={name} {...props} />;
        return Translate;
    }

    /**
     * Load, register, and returns locale sections
     * @param {String[]} names
     * @param {String} [locale]
     * @returns {Promise<Locale[]>}
     */
    static async loadSections(names, locale) {
        const namesToLoad = names.filter(name => locale || !this.sections[name]);

        if (namesToLoad.length) {
            const params = namesToLoad.map(section => `sections[]=${encodeURIComponent(section)}`);
            if (locale) {
                params.push(`locale=${encodeURIComponent(locale)}`);
            }
            try {
                const sections = await api.get(`/cp/locale?${params.join('&')}`);
                Object.keys(sections).forEach(name => {
                    this.addSection(name, sections[name]);
                });
            } catch {}
        }

        return names.map(name => this.getSection(name));
    }

    /**
     * Load, register, and returns locale section
     * @param {String} name
     * @returns {Promise<Locale>}
     */
    static async loadSection(name) {
        const [locale] = await this.loadSections([name]);
        return locale;
    }

    constructor(messages = {}) {
        this.messages = messages;
    }

    /**
     * Get locale message by key.
     * @param {String} key
     * @param {Object} [params]
     * @returns {String}
     */
    lmsg(key, params) {
        const value = this.messages[key];
        if ('undefined' === typeof value) {
            return `[${key}]`;
        }

        if ('undefined' === typeof params) {
            return value;
        }

        return Object.keys(params)
            .reduce((value, paramKey) => value.replace(`%%${paramKey}%%`, params[paramKey]), value);
    }

    /**
     * Get formatted locale message by key.
     * @param {String} key
     * @param {Object} [params]
     * @returns {String}
     */
    formatMessage(key, params) {
        return new IntlMessageFormat(this.lmsg(key, params), document.documentElement.lang || 'en-US').format(params);
    }
}

export default Locale;
