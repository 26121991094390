// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Container } from './container';
import Cookie from 'common/cookie';
import createComponent from './createComponent';
import render from './render';

/**
 * Example usage:
 *
 *     @example
 *     new Panel({
 *         renderTo: document.body,
 *         title: 'Panel title',
 *         items: [
 *             new Box({
 *                 html: 'Panel content'
 *             }),
 *         ],
 *     });
 */
export class Panel extends Container {
    /**
     * @cfg {String} title
     */
    /**
     * @cfg {String} titleClass
     */
    /**
     * @cfg {Boolean} hideContentTitle
     */

    _initConfiguration(config) {
        super._initConfiguration(config);

        this._title = this._getConfigParam('title', '');
        this._titleClass = this._getConfigParam('titleClass', '');
        this._hideContentTitle = this._getConfigParam('hideContentTitle', this._title);
        this._titleAreaId = `${this._id}-title-area`;
        this._contentAreaId = `${this._id}-content-area`;
        this._boxItemsAreaId = `${this._id}-box-area`;
        this._initBoxItems();
    }

    _initBoxItems() {
        this._boxItems = this._getConfigParam('boxItems', null);

        if (null !== this._boxItems) {
            this._boxItems = this._boxItems.map(createComponent);
        }
    }

    _initComponentElement() {
        super._initComponentElement();

        this._updateComponentElement((
            `<div class="box-area">${this._getTitleHtml()}${this._getSubTitleHtml()}` +
                `<div id="${this._boxItemsAreaId}"></div>` +
                '<div class="content">' +
                    '<div class="content-area">' +
                        `<div class="content-wrapper" id="${this._contentAreaId}">${this._getConfigParam('html', '')}</div>` +
                    '</div>' +
                '</div>' +
            '</div>'
        ));
    }

    _getTitleHtml() {
        return this._title
            ? `<div class="title ${this._titleClass ? this._titleClass : ''}">` +
                    `<div class="title-area" id="${this._titleAreaId}">${
                        ('-' === this._title) ? '' : `<h3><span>${this._title}</span></h3>`
                    }</div>` +
                '</div>'
            : '';
    }

    _getSubTitleHtml() {
        return '';
    }

    render() {
        super.render();

        this._renderBoxItems();
    }

    _renderBoxItems() {
        if (null !== this._boxItems) {
            this._boxItems.forEach(function (item) {
                this._renderBoxItem(item);
            }, this);
        }
    }

    _renderBoxItem(item) {
        render(document.getElementById(this._boxItemsAreaId), item);
    }

    _isHidden() {
        const rememberedStatus = Cookie.get(`${this._id}-hidden`);
        if (this._idIsRandom || null === rememberedStatus) {
            return this._getConfigParam('collapsed', false);
        }
        return 1 == rememberedStatus;
    }

    _setHidden(hidden) {
        if (this._getConfigParam('collapsed', false) == hidden) {
            Cookie.remove(`${this._id}-hidden`);
        } else {
            Cookie.set(`${this._id}-hidden`, hidden ? 1 : 0);
        }
    }

    _addEvents() {
        super._addEvents();
        if (this._isHidden()) {
            this.hideContent();
        } else {
            this.showContent();
        }
        this._componentElement.querySelectorAll(`#${this._titleAreaId}`).forEach(titleAreaElement => {
            titleAreaElement.addEventListener('click', () => {
                this.toggleContent();
            });
        });
    }

    _updateTitle(title) {
        if (title && ('-' !== title)) {
            this._componentElement.querySelector('div.title-area > h3 > span').innerHTML = title;
        }
    }

    showContent() {
        this._componentElement.classList.remove('hide');
        this._updateTitle(this._hideContentTitle);
        if (!this._idIsRandom) {
            this._setHidden(false);
        }
    }

    hideContent() {
        this._componentElement.classList.add('hide');
        this._updateTitle(this._title);
        if (!this._idIsRandom) {
            this._setHidden(true);
        }
    }

    toggleContent() {
        this._componentElement.classList.contains('hide')
            ? this.showContent()
            : this.hideContent();
    }
}
