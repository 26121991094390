// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import PropTypes from 'prop-types';
import { List, Pagination } from '@plesk/ui-library';
import { GraphQlListContext } from './GraphQlListContext';
import GraphQlListSearchBar from './GraphQlListSearchBar';

const GraphQlList = ({
    onSortChange,
    filtered,
    pagination,
    emptyView,
    emptyViewFiltered,
    listContext,
    ...listProps
}) => {
    const { paginationProps, setSort } = listContext;
    const isLoadingOrFiltered = filtered || listProps.loading;

    return (
        <GraphQlListContext.Provider value={listContext}>
            <List
                filtered={isLoadingOrFiltered}
                pagination={pagination || (paginationProps && <Pagination {...paginationProps} />)}
                emptyView={isLoadingOrFiltered ? emptyViewFiltered : emptyView}
                {...listProps}
                onSortChange={(...args) => {
                    onSortChange?.(...args);
                    const [sort] = args;
                    setSort({ [sort.sortColumn]: sort.sortDirection });
                }}
            />
        </GraphQlListContext.Provider>
    );
};

GraphQlList.propTypes = {
    onSortChange: PropTypes.func,
    listContext: PropTypes.object.isRequired,
    filtered: PropTypes.bool,
    pagination: PropTypes.node,
    emptyView: PropTypes.node,
    emptyViewFiltered: PropTypes.node,
};

GraphQlList.defaultProps = {
    onSortChange: undefined,
    filtered: undefined,
    pagination: undefined,
    emptyView: undefined,
    emptyViewFiltered: undefined,
};

GraphQlList.SearchBar = GraphQlListSearchBar;

export default GraphQlList;
