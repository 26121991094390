// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { useCallback } from 'react';
import { createPath } from 'react-router';
import { useNavigate as useNavigateBase } from 'react-router-dom';

const useNavigate = () => {
    const navigateBase = useNavigateBase();

    return useCallback((to, options = {}) => {
        if (!require('routes').isClientSideRedirectAllowed(to)) {
            window.location[options?.replace ? 'replace' : 'assign'](typeof to === 'object' && to !== null ? createPath(to) : to);
        } else {
            navigateBase(to, options);
        }
    }, [navigateBase]);
};

export default useNavigate;
