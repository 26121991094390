// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import { Component, getComponent } from './component';
import { redirect, redirectPost } from './form-redirect';
import Observer from './Observer';
import addStatusMessage from './addStatusMessage';
import clearStatusMessages from './clearStatusMessages';
import showInternalError from './showInternalError';
import render from './render';
import escapeHtml from './escapeHtml';

export class FormAjax extends Component {
    _initConfiguration(config) {
        this._actionButtonTitle = '';

        super._initConfiguration(config);
        this._sendButtonId = this._getConfigParam('sendButtonId', 'btn-send');
        this._applyButtonId = this._getConfigParam('applyButtonId', 'btn-apply');
        this._cancelButtonId = this._getConfigParam('cancelButtonId', 'btn-cancel');
        this._confirmationCheckboxId = this._getConfigParam('confirmationCheckboxId', 'formNeedAttention-attentionConfirmed');
        this._submitHandler = this._getConfigParam('submitHandler', () => true);
    }

    disable() {
        [this._sendButtonId, this._applyButtonId, this._cancelButtonId].forEach(buttonId => {
            this._toggleButton(buttonId, true);
        });

        this._toggleCheckbox(this._confirmationCheckboxId, true);

        const actionButton = this._getActionButton();
        if (actionButton) {
            this._actionButtonTitle = actionButton.innerHTML;
            actionButton.innerHTML = `<span class="wait">${this._config.waitButtonTitle}</span>`;
        }
    }

    enable() {
        [this._sendButtonId, this._applyButtonId, this._cancelButtonId].forEach(buttonId => {
            this._toggleButton(buttonId, false);
        });

        this._toggleCheckbox(this._confirmationCheckboxId, false);

        const actionButton = this._getActionButton();
        if (actionButton) {
            actionButton.innerHTML = this._actionButtonTitle;
        }
    }

    _toggleButton(buttonId, disable) {
        const button = getComponent(buttonId);
        if (button) {
            button[disable ? 'disable' : 'enable']();
        }
    }

    _toggleCheckbox(checkboxId, disable) {
        const checkbox = document.getElementById(checkboxId);
        if (checkbox) {
            checkbox.disabled = disable;
        }
    }

    _getActionButton() {
        return document.getElementById(this._componentElement.noRedirect ? this._applyButtonId : this._sendButtonId);
    }

    _initComponentElement() {
        this._componentElement = document.getElementById(this._id);
        this._initDisablerOverlay();

        if (!this._componentElement.dataset?.noInitFocus) {
            const firstElement = this._componentElement.querySelector('input, select, textarea');

            if (firstElement) {
                try {
                    firstElement.focus();
                } catch {}
            }
        }

        this._submitInProgress = false;

        const toggleButtons = disable => {
            [this._sendButtonId, this._applyButtonId].forEach(id => this._toggleButton(id, disable));
        };

        const confirmationCheckbox = document.getElementById(this._confirmationCheckboxId);
        if (confirmationCheckbox) {
            toggleButtons(!confirmationCheckbox.checked);
            confirmationCheckbox.addEventListener('click', e => {
                toggleButtons(!e.currentTarget.checked);
            });
        }
    }

    _addEvents() {
        this._componentElement._formSubmit = this._componentElement.submit;
        this._componentElement.submit = this._onSubmit.bind(this);
        this._componentElement.addEventListener('submit', this._onSubmitEvent.bind(this));
        this._addChoiceRadioButtonsOnClickEvent();
    }

    _addChoiceRadioButtonsOnClickEvent() {
        document.querySelectorAll('input[type="radio"]').forEach(element => {
            if (!element.closest('div.choice-block')) {
                return;
            }
            element.addEventListener('click', function () {
                element.closest('div.choice-block').querySelectorAll('span').forEach(element => {
                    element.classList.remove('selected');

                    if ('0' === element.value) {
                        element.classList.remove('no');
                    }
                });

                element.closest('span').classList.add('selected');

                if ('0' === element.value) {
                    element.closest('span').classList.add('no');
                }
            });
        });
    }

    _onSubmitEvent(event) {
        this._onSubmit();
        event.preventDefault();
        return false;
    }

    async _onSubmit() {
        if (!await this._submitHandler()) {
            return false;
        }

        if (this._submitInProgress) {
            return false;
        }
        this._submitInProgress = true;

        this._clearMessages();
        this.disable();

        if (this._componentElement.enctype === 'multipart/form-data') {
            this._componentElement._formSubmit();
            return true;
        }

        let actionUrl = this._componentElement.getAttribute('action');

        if (!actionUrl) {
            actionUrl = document.location.href;
        }

        // remove hash symbol (and the following) cause this will encoded and lead to problems
        actionUrl = actionUrl.replace(/#.*$/, '');

        const params = Form.serialize(this._componentElement);
        new Ajax.Request(actionUrl, {
            method: 'post',
            parameters: params,
            onSuccess: this._onSuccess.bind(this),
            onFailure: this._onFailure.bind(this),
        });
        return true;
    }

    _onFailure(req) {
        this._submitInProgress = false;
        this._clearMessages();

        if (504 === req.status) {
            addStatusMessage('error', this._config.timeoutMessage);
        } else {
            showInternalError(req.responseText);
        }

        this.enable();
    }

    _onSuccess(req) {
        this._submitInProgress = false;
        clearStatusMessages();

        try {
            const response = JSON.parse(req.responseText);
            this._processForm(response);
        } catch {
            this._clearMessages();
            this.enable();

            // show error, if request wasn't aborted by user
            if (0 != req.status) {
                showInternalError(req.responseText);
            }
        }
    }

    _processForm(response) {
        if (response.componentType === 'Jsw.Task.ProgressBar.Item') {
            getComponent('asyncProgressBarWrapper').progressDialog(response);
            Observer.append(task => {
                if (task.id === response.id) {
                    this.enable();
                }
            }, 'plesk:taskComplete');
            return;
        }

        if (response.redirect) {
            this._processResponseRedirect(response);
            return;
        }

        this._clearMessages();
        this._processResponseStatus(response.status);
        this._processResponseStatusMessages(response.statusMessages);
        this._processResponseFormMessages(response.formMessages);

        if (this._hasFieldErrors) {
            this._showFieldErrorArea();
        }
        this.enable();
    }

    _showFieldErrorArea() {
        const errorsElements = this._componentElement.querySelectorAll('.field-errors');
        let firstError = null;

        errorsElements.forEach(errorElement => {
            if (errorElement.style.display !== 'none') {
                firstError = errorElement;
            }
        });

        let hiddenContainerId = null;

        let element = firstError;
        while ((element = element.parentNode) && element.nodeType === Node.ELEMENT_NODE) {
            if (element.style.display === 'none') {
                hiddenContainerId = element.id;
            }
        }

        if (!hiddenContainerId) {
            return;
        }

        // in case of tabbed form we must switch to corresponding tab
        const tabsBar = getComponent('form-tab-buttons');

        if (tabsBar) {
            tabsBar.switchTab(hiddenContainerId);
        }
    }

    _processResponseStatus(status) {
        if ('success' !== status) {
            this._hasErrors = true;
        }
    }

    _clearMessages() {
        // clear field messages
        try {
            this._componentElement.querySelectorAll('.field-errors').forEach(errors => {
                errors.style.display = 'none';
                const rowElement = errors.closest('.form-row');
                if (rowElement) {
                    rowElement.classList.remove('error');
                }
                errors.querySelectorAll('.error-hint').forEach(error => {
                    error.parentNode.removeChild(error);
                });
            });
        } catch {
        }

        this._hasErrors = false;
        this._hasFieldErrors = false;
    }

    _processResponseStatusMessages(messages) {
        (messages || []).forEach(({ status, content, title }) => {
            this._addFormMessage(status, content, title);
        });
    }

    _processResponseFormMessages(messages) {
        this._processFieldMessages(messages, []);
    }

    _addFieldMessage(errors, message) {
        errors.closest('.form-row').classList.add('error');
        render(errors, `<span class="error-hint">${escapeHtml(message)}</span>`);
        errors.style.display = '';
    }

    _processFieldMessage(prefix, name, message) {
        let fieldErrors;
        const formElement = this._componentElement.querySelector(`#${prefix.join('-')}`);
        fieldErrors = formElement ? formElement.parentNode.querySelector('.field-errors') : null;
        if (!fieldErrors) {
            fieldErrors = formElement ? formElement.closest('.form-row').querySelector('.field-errors') : null;
        }
        if (!fieldErrors) {
            fieldErrors = this._componentElement.querySelector(`#${prefix.join('-')}-form-row`).querySelectorAll('.field-errors');
            fieldErrors = fieldErrors[fieldErrors.length - 1];
        }
        this._addFieldMessage(fieldErrors, message);
        this._hasFieldErrors = true;
    }

    _processFieldMessages(messages, prefix) {
        if (Array.isArray(messages)) {
            messages.forEach(message => {
                if (typeof message === 'string') {
                    this._processFieldMessage(prefix, 'error', message);
                } else {
                    prefix.push(name);
                    this._processFieldMessages(message, prefix);
                    prefix.pop();
                }
            });
        } else {
            $H(messages).each(({ key, value }) => {
                if (typeof value === 'string') {
                    this._processFieldMessage(prefix, key, value);
                } else {
                    prefix.push(key);
                    this._processFieldMessages(value, prefix);
                    prefix.pop();
                }
            });
        }
    }

    _addFormMessage(type, message, title) {
        addStatusMessage(type, message, { title });
    }

    _processResponseRedirect(response) {
        if (this._componentElement.noRedirect) {
            document.location.reload();
        } else if (response.postData) {
            redirectPost(response.redirect, response.postData, response.target);
        } else {
            redirect(response.redirect, null, response.target);
        }
    }
}
