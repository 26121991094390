// Copyright 1999-2024. Plesk International GmbH. All rights reserved.

import render from '../render';

export class UploadAdapterIframe {
    constructor({ contentAreaId, handlerUrl, startUploadHandler, finishUploadHandler }) {
        this._contentAreaId = contentAreaId;
        this._handlerUrl = handlerUrl;
        this._startUploadHandler = startUploadHandler;
        this._finishUploadHandler = finishUploadHandler;
        this._uploadFrameInitialized = false;
        this._getUploadIFrameElement();
    }

    _getUploadIFrameElement() {
        const contentArea = document.getElementById(this._contentAreaId);
        if (!contentArea.querySelector('iframe')) {
            this._uploadFrameElement = new Element('iframe', {
                id: `${this._contentAreaId}-upload-iframe`,
                name: '_uploadIFrame',
                style: 'display: none;',
                src: this._handlerUrl,
            });
            render(contentArea, this._uploadFrameElement);
            this._uploadFrameElement.addEventListener('load', this._onUploadFrameInitialized.bind(this));
        }
        return this._uploadFrameElement;
    }

    _onUploadFrameInitialized() {
        if (this._uploadFrameInitialized) {
            return;
        }
        this._uploadFrameInitialized = true;
        this._uploadFrameElement.addEventListener('load', this._finishUploadHandler);
        this._startUploadHandler();
    }

    isReady() {
        return this._uploadFrameInitialized;
    }

    getResult() {
        const statusFrame = this._getUploadIFrameElement();
        const statusDocument = statusFrame.contentDocument || statusFrame.contentWindow.document;
        try {
            return JSON.parse(statusDocument.body.innerHTML);
        } catch {
            return {
                status: 'ERROR',
                message: 'No upload response',
            };
        }
    }

    cancel() {
        if (this._uploadFrameElement) {
            this._uploadFrameElement.setAttribute('src', 'javascript:false;');
        }
        this._finishUploadHandler();
    }
}
